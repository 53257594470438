import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/footer-bg.png";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="text-content wow zoomIn">
                    <h3>Experience in Tools & Frameworks</h3>
                    <p>HTML5, CSS3, SASS, Tailwind CSS, PrimeFaces & Storybook.
                        <br></br>
                        Swagger UI, Postman, JSON, Rest API Automation Testing (Rest-Assured).
                        <br></br>
                        C, Bash, Shell Scripting, XML, UML, JavaScript, TypeScript, Java, ReactJs, AngularJs, VUE,
                        TypeScript, NodeJs.
                        <br></br>
                        Spring Boot 3, Spring 6 (MVC), JWT, MySQL, JDBC, Express, MongoDB, Maven, JPA & Hibernate.
                        <br></br>
                        JUnit Testing (Java), Jest (ReactJs & JavaScript).
                        <br></br>
                        GitHub, Git, Jenkins, BitBucket, Docker, Kubernetes, Circle CI.
                        <br></br>
                        Eclipse (Equinox), Apache (Felix & Karaf) OSGi Containers/Servers, GlassFish & WebSphere Liberty
                        (WAS), LDAP & JNDI.
                        <br></br>
                        Micro-frontends (with React, Angular & VUE).
                        <br></br>
                        Microservices (with Spring Boot & Spring Cloud).
                        <br></br>
                        Code Editors/IDEs - CodeBlocks, Atom, Notepad++, Visual Studio, Netbeans, Eclipse & IntelliJ.
                        <br></br>
                    </p>
                    <br/>
                    <h3>Projects Worked on</h3>
                    <p>e@syfile Employer - SARS
                        <br></br>
                        UIF - UME
                        <br></br>
                        uFiling - (UIF - FTM)
                        <br></br>
                        eCSOS
                        <br></br>
                    </p>
                    <br></br>
                    <h3>Contact Me</h3>
                    <p>Email - contact@tshepoboikanyo.co.za
                        <br></br>
                        Phone - 071 112 2042
                    </p>
                    {/*<Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">*/}
                    {/*    <div className="item">*/}
                    {/*        <img src={meter1} alt="Image" />*/}
                    {/*        <h5>Web Development</h5>*/}
                    {/*    </div>*/}
                    {/*    <div className="item">*/}
                    {/*        <img src={meter2} alt="Image" />*/}
                    {/*        <h5>Brand Identity</h5>*/}
                    {/*    </div>*/}
                    {/*    <div className="item">*/}
                    {/*        <img src={meter3} alt="Image" />*/}
                    {/*        <h5>Logo Design</h5>*/}
                    {/*    </div>*/}
                    {/*    <div className="item">*/}
                    {/*        <img src={meter1} alt="Image" />*/}
                    {/*        <h5>Web Development</h5>*/}
                    {/*    </div>*/}
                    {/*</Carousel>*/}
                </div>
            </div>
        </div>
        {/*<img className="background-image-left" src={colorSharp} alt="Image" />*/}
    </section>
  )
}
