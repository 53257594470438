import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myself from "../assets/img/1672819046157.jpg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
// import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Web Developer"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  };

  return (
      <section className="banner" id="home">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={6} xl={4} className="d-flex justify-content-center">
              <TrackVisibility>
                {({ isVisible }) =>
                    <div className="skill-bx wow zoomIn" style={{marginTop: '140px'}}>
                      <div className="animate__animated circular--square circular--portrait">
                        <img src={myself} alt="myself" />
                      </div>
                    </div>}
              </TrackVisibility>
            </Col>
            <Col xs={12} md={6} xl={8}>
              <TrackVisibility>
                {({isVisible}) =>
                    <div className="text-content wow zoomIn" style={{marginTop: '100px'}}>
                      <h3 className="text-white-50">Welcome to my Portfolio</h3>
                      <h1>{`Hi there, I'm Tshepo`}</h1>
                      <p>
                        I am a bright, talented, and ambitious individual with a strong technical background, possesses
                        self-discipline and the ability to work with minimum supervision.
                        Having exposure to a wide range of work & able to play a key role and ensure that quality
                        solutions meet business objectives.
                        Possesses a good team spirit, deadline orientated, and ability to organize and present complex
                        solutions clearly and accurately.
                        I am looking for a suitable position with a successful and dynamic company that offers room for
                        progression.
                      </p>
                      <p>
                        I am able to build ReactJs/JavaScript/AngularJS applications with just the right tools,
                        and I have built products for companies and businesses ranging from marketing websites to
                        complex solutions and
                        enterprise apps with focus on fast, elegant and accessible user experiences.
                      </p>
                    </div>}
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
      </section>
  );
};